<template>
  <div class="w-full">
    <div class="mx-auto absolute flex flex-col w-full divide-y divide-gray">
      <div
          :class="isBackgroundColor || !changeColor || !isHomePage ? 'bg-white text-primary' : 'bg-transparent text-white'"
          class="grid grid-cols-3 py-2.5 md:py-5 lg:px-4 items-center"
      >
        <div class="flex items-center lg:hidden">
          <UIMobileMenuButton label="Menü" />

          <UIMobileSearchButton :class="isBackgroundColor || !changeColor || !isHomePage ? '!text-primary' : '!text-white'" />
        </div>
        <div class="lg:flex hidden items-center justify-start">
          <LayoutNavigationMenu />
        </div>

        <Logo class="order-3 lb-nav-logo" :class="isBackgroundColor || !changeColor || !isHomePage ? 'fill-primary' : 'fill-white invert'"/>

        <!-- Üst Sağ - Kullanıcı Menu Alanı-->
        <div class="flex items-center justify-end gap-2 lg:gap-5 order-4 pr-2.5">
          <!-- Arama Formu -->
          <SearchIcon
              @click="openCombobox = true"
              class="h-6 w-6 lg:block hidden"
              :class="isBackgroundColor || !changeColor || !isHomePage ? 'fill-primary' : 'fill-white'"
          />
          <template v-if="status === 'authenticated'">
            <UserDropdownMenu :color="isBackgroundColor || !changeColor || !isHomePage ? '!text-primary' : ''">
              {{ data?.user?.name }}
            </UserDropdownMenu>
          </template>
          <template v-else-if="status === 'unauthenticated'">
            <NuxtLinkLocale href="/login" class="lb-nav-item-rgt">
              {{ $t("auth.sign-in") }}
            </NuxtLinkLocale>
            <NuxtLinkLocale href="/register" class="lb-nav-item-rgt">
              {{ $t("auth.sign-up") }}
            </NuxtLinkLocale>
          </template>
          <UIMobileUserButton :class="isBackgroundColor || !changeColor || !isHomePage ? '!text-primary' : '!text-white'" />
          <!-- Sepet Bölümü-->
          <ShoppingCartMenu class="group order-6">
            <ShoppingBag
                class="w-4 h-4 group-hover:fill-secondary"
                :class="
                isBackgroundColor || !changeColor || !isHomePage
                  ? 'fill-primary'
                  : 'fill-white'
              "
            />
          </ShoppingCartMenu>
        </div>
      </div>
      <SearchInput
        class="bg-white flex lg:hidden -mt-2.5"
        v-if="isShowMobileSearch"
        is-expandabled="true"
      />
      <SearchCombobox />
    </div>
  </div>
</template>

<script setup>
import ShoppingBag from "~/assets/icons/shopping-bag.svg";
import SearchIcon from "~/assets/icons/search.svg"
const route = useRoute();
const router = useRouter();

const { status, data } = await useAuth()
const { isShowMenu, isShowMobileSearch } = useHelper()
const isBackgroundColor = useState("isBackgroundColor", () => false)
const changeColor = useState("changeColor", () => true)
const scrollPosition = useState("scrollPosition", () => null)
const isHomePage = useState("isHomePage", () => true)
const openCombobox = useState("openCombobox", () => false)

onMounted(() => {
  if (typeof window !== 'undefined') {
    window.addEventListener("scroll", updateScroll);
  }
});

const updateScroll = () => {
  scrollPosition.value = window.scrollY;
  changeColor.value = scrollPosition.value <= 100;
};

watchEffect(() => {
  isHomePage.value = route.path === "/";
});
</script>
